
import util from '../../util';
export default {
  name: 'status',
  computed: {
    env(): string {
      return util.getEnvForProductLink(true);
    },
    formattedEnv(): string {
      let envName = this.env;
      switch (envName) {
        case 'local':
        case 'dev':
          break;
        case 'stg':
          envName = 'staging';
          break;
      }
      return envName.toUpperCase();
    },
    envBarStyle(): object {
      const colorsMapping = {
        LOCAL: '#77bb41',
        DEV: '#03c7fc',
        STAGING: '#ff9301',
      };
      return {
        backgroundColor: colorsMapping[this.formattedEnv],
        marginTop: '0px',
      };
    },
  },
};
