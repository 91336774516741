/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios';
import CONSTANTS from './constants';
import { logError } from '../../index';
import { session_id } from '../../../main';
import { Conversions } from '@point/utility-classes';
import {
  ConversionsPayload,
  DailyPerformancePayload,
  GeoPerformancePayload,
  GeoListPerformancePayload,
  ConversionsBreakdownTypeRawData,
} from './types';

const getConversions = async ({ commit, state }, payload: ConversionsPayload): Promise<Conversions> => {
  const { advertiserId, campaignDashboardIds, resolution, daterange, startdate = '', enddate = '' } = payload;
  commit(CONSTANTS.SET_CONVERSIONS_ERROR, null);

  if (!advertiserId || !daterange || !campaignDashboardIds || !resolution) {
    commit(CONSTANTS.SET_CONVERSIONS_ERROR, 'Missing parameters');
    return;
  }

  let conversionsUrl;
  const headers = { session_id };

  if ((!startdate || !enddate) && daterange) {
    conversionsUrl = `/api/advertiser/conversion?advertiserId=${advertiserId}&daterange=${daterange}&campaignDashboardIds=${campaignDashboardIds}&resolution=${resolution}`;
  } else {
    conversionsUrl = `/api/advertiser/conversion?advertiserId=${advertiserId}&campaignDashboardIds=${campaignDashboardIds}&resolution=${resolution}&startDate=${startdate}&endDate=${enddate}`;
  }

  if (
    state.conversionsCache === conversionsUrl &&
    state.isConversionsCached &&
    state.conversions &&
    !state.conversionsError
  ) {
    return state.conversions;
  }

  try {
    commit(CONSTANTS.SET_IS_CONVERSIONS_LOADING, true);
    const { data }: AxiosResponse<Conversions> = await axios.get(conversionsUrl, { headers });
    commit(CONSTANTS.SET_CONVERSIONS, data);
    commit(CONSTANTS.SET_CONVERSIONS_CACHE, conversionsUrl);
    commit(CONSTANTS.SET_IS_CONVERSIONS_CACHED, true);

    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_CONVERSIONS_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getConversions');
  } finally {
    commit(CONSTANTS.SET_IS_CONVERSIONS_LOADING, false);
  }
};

const getConversionsBreakdown = async ({ commit, state }, payload): Promise<ConversionsBreakdownTypeRawData> => {
  const { advertiserId, daterange, campaigns, startdate = '', enddate = '' } = payload;
  commit(CONSTANTS.SET_CONVERSIONS_BREAKDOWN_ERROR, null);

  if (!advertiserId || !daterange || !campaigns?.length) {
    commit(CONSTANTS.SET_CONVERSIONS_BREAKDOWN_ERROR, 'Missing parameters');
    return;
  }

  const body: Record<string, unknown> = {
    advertiserId,
    daterange,
    campaigns,
  };

  const conversionBreakdownUrl = `/api/advertiser/performance/getConversionBreakdown`;
  const headers = { session_id };

  if (!startdate || !enddate) {
    body.daterange = daterange;
  } else {
    body.startDate = startdate;
    body.endDate = enddate;
    body.daterange = 'alltime';
  }
  try {
    commit(CONSTANTS.SET_IS_CONVERSIONS_BREAKDOWN_LOADING, true);
    const { data }: AxiosResponse<ConversionsBreakdownTypeRawData> = await axios.post(conversionBreakdownUrl, body, {
      headers,
    });
    commit(CONSTANTS.SET_CONVERSIONS_BREAKDOWN, data);
    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_CONVERSIONS_BREAKDOWN_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getConversionsBreakdown');
  } finally {
    commit(CONSTANTS.SET_IS_CONVERSIONS_BREAKDOWN_LOADING, false);
  }
};

const getDailyPerformance = async ({ commit }, payload: DailyPerformancePayload): Promise<unknown> => {
  const { advertiserId, daterange, campaigns, startdate = '', enddate = '' } = payload;
  commit(CONSTANTS.SET_DAILY_PERFORMANCE_ERROR, null);

  if (!advertiserId || !daterange || !campaigns?.length) {
    commit(CONSTANTS.SET_DAILY_PERFORMANCE_ERROR, 'Missing parameters');
    return;
  }

  const body: Record<string, unknown> = {
    advertiserId,
    daterange,
    campaigns,
  };

  const dailyPerformanceUrl = `/api/advertiser/performance/daily`;
  const headers = { session_id };

  if (!startdate || !enddate) {
    body.daterange = daterange;
  } else {
    body.startDate = startdate;
    body.endDate = enddate;
    body.daterange = 'alltime';
  }
  try {
    commit(CONSTANTS.SET_IS_DAILY_PERFORMANCE_LOADING, true);
    const data: AxiosResponse<unknown> = await axios.post(dailyPerformanceUrl, body, { headers });
    commit(CONSTANTS.SET_DAILY_PERFORMANCE, data);
    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_DAILY_PERFORMANCE_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getDailyPerformance');
  } finally {
    commit(CONSTANTS.SET_IS_DAILY_PERFORMANCE_LOADING, false);
  }
};

const getGeoPerformance = async ({ commit }, payload: GeoPerformancePayload): Promise<unknown> => {
  const { advertiserId, daterange, campaigns, startdate = '', enddate = '' } = payload;
  commit(CONSTANTS.SET_GEO_PERFORMANCE_ERROR, null);

  if (!advertiserId || !daterange || !campaigns?.length) {
    commit(CONSTANTS.SET_GEO_PERFORMANCE_ERROR, 'Missing parameters');
    return;
  }

  const body: Record<string, unknown> = {
    advertiserId,
    daterange,
    campaigns,
  };

  const geoPerformanceUrl = `/api/advertiser/performance/geo-performance`;
  const headers = { session_id };

  if (!startdate || !enddate) {
    body.daterange = daterange;
  } else {
    body.startDate = startdate;
    body.endDate = enddate;
    body.daterange = 'alltime';
  }
  try {
    commit(CONSTANTS.SET_IS_GEO_PERFORMANCE_LOADING, true);
    const data: AxiosResponse<unknown> = await axios.post(geoPerformanceUrl, body, { headers });
    commit(CONSTANTS.SET_GEO_PERFORMANCE, data);
    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_GEO_PERFORMANCE_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getGeoPerformance');
  } finally {
    commit(CONSTANTS.SET_IS_GEO_PERFORMANCE_LOADING, false);
  }
};

const getGeoListPerformance = async ({ commit }, payload: GeoListPerformancePayload): Promise<unknown> => {
  const { advertiserId, daterange, campaigns, startdate = '', enddate = '', isShared } = payload;
  commit(CONSTANTS.SET_GEO_PERFORMANCE_ERROR, null);

  if (!advertiserId || !daterange || !campaigns?.length) {
    commit(CONSTANTS.SET_GEO_PERFORMANCE_ERROR, 'Missing parameters');
    return;
  }

  const body: Record<string, unknown> = {
    advertiserId,
    daterange,
    campaigns,
    isShared,
  };

  const geoListPerformanceUrl = `/api/advertiser/performance/geo-list`;
  const headers = { session_id };

  if (!startdate || !enddate) {
    body.daterange = daterange;
  } else {
    body.startDate = startdate;
    body.endDate = enddate;
    body.daterange = 'alltime';
  }
  try {
    commit(CONSTANTS.SET_IS_GEO_LIST_PERFORMANCE_LOADING, true);
    const data: AxiosResponse<unknown> = await axios.post(geoListPerformanceUrl, body, { headers });
    commit(CONSTANTS.SET_GEO_LIST_PERFORMANCE, data);
    return data;
  } catch (exp) {
    commit(CONSTANTS.SET_GEO_LIST_PERFORMANCE_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.performance.getGeoListPerformance');
  } finally {
    commit(CONSTANTS.SET_IS_GEO_LIST_PERFORMANCE_LOADING, false);
  }
};

export default {
  getConversions,
  getConversionsBreakdown,
  getDailyPerformance,
  getGeoPerformance,
  getGeoListPerformance,
};
